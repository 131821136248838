.sprinkles-div{
    position: relative;
    z-index: 999999999 !important;
    width: 500px;
height: 600px;
background: url('/public/sprinkles.svg');
/* background-color: #FFF; */
/* opacity: 0.15; */
box-shadow: inset 0px 0px 10px rgba(248, 248, 248, 0.6);
display: flex;
flex-direction: column;
align-items: center;
row-gap: 10px;
}
.sprinkles{
    width: 334px;
}
.sprinkles p{
    font-family: 'Proxima Nova';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 24px;
/* identical to box height */

text-align: center;
color: #25373F;

}
.sprinkles span{
    font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* or 150% */
text-align: center !important;
letter-spacing: 0.01em;

/* Gray */

color: #595F62;

}
.btn_share{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px 18px;
gap: 8px;

width: 392px;
height: 44px;

/* Primary */

background: #3A86F4;
box-shadow: 0px 3px 0px #2F71D0;
border-radius: 8px;

font-family: 'Rubik';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


/* White */

color: #FFFFFF !important;
}