.containerSlider {
  /* height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px;
}

.slider {
  position: relative;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  /* background-color: #9fe5e1; */
  background-color: #3a86f4;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  /* color: #dee2e6;
    font-size: 12px;
    margin-top: 2px; */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #25373f;
  opacity: 0.7;
}

.slider__left-value {
  left: -70px;
}

.slider__right-value {
  right: -80px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 112px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .slider {
    width: 58px !important;
  }
  .slider__left-value, .slider__right-value{
    font-size: 12px !important;
  }
  .thumb{
    width: 70px !important;
  }
}
@media only screen and (max-width: 991px)  {
  .slider {
    width: 58px !important;
  }
  .slider__left-value, .slider__right-value{
    font-size: 12px !important;
  }
  .slider__left-value{
    left: -55px !important;
  }
  .slider__right-value{
    right: -68px !important;
  }
  .thumb{
    width: 70px !important;
  }
}
